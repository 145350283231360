// Copyright 2023-2024 - Hewlett Packard Enterprise Company
import PropTypes from 'prop-types';
/* eslint-disable quotes */

// Poll interval (15 sec) when messaging is not active
export const POLL_INTERVAL = 15000;

// Poll interval (3 sec) to get job status by polling
export const JOB_POLL_INTERVAL = 3000;

// Poll interval (2 sec) to get job status by polling
export const SSO_JOB_POLL_INTERVAL = 2000;

// Total poll time (10 sec) to get sso job status by polling
export const TOTAL_SSO_JOB_POLL_TIME = 10000;

// Poll interval (60 sec) when messaging is active
export const SAFETY_NET_POLL_INTERVAL = 60000;

// Accumulate websocket update messages for 5 seconds
export const MSG_BATCH_INTERVAL = 5000;

// When showing toast after closing a wizard/modal,
// delay showing the toast by .5 seconds to reduce
// flicker
export const TOAST_FLICKER_DELAY = 500;

// .5 seconds is long enough to communicate action is in motion,
// but not too quick that it is a distracting flash.
export const MIN_SPINNER_DURATION = 500;

// Minimum time to wait for updates to be pushed to eagle
export const MIN_EAGLE_WAIT = 2000;

// Derived state filter values to show all
// activated servers
export const ACTIVATED_FILTER_VALUES = ['Connected', 'Not connected'];

// Derived state filter values including subscription
// state used to show all activated servers
export const SUBSCRIPTION_ACTIVATED_FILTER_VALUES = [
  'Connected',
  'Not connected',
  'Subscription required',
  'Subscription expired',
];

export const COMPLIANCE_COMPLIANT = 'COMPLIANT';
export const COMPLIANCE_NOT_COMPLIANT = 'NOT_COMPLIANT';
export const COMPLIANCE_UNKNOWN = 'UNKNOWN';
export const COMPLIANCE_NOT_APPLICABLE = 'NOT_APPLICABLE';
export const DERIVED_STATE_NOT_SET = '-';
export const FIRMWARE_BUNDLE_NOT_SET = '--';
export const FIRMWARE_BUNDLE_NOT_APPLICABLE = 'Not applicable';
export const FIRMWARE_BUNDLE_FILTERKEYS_NOT_SET = 'Not set';
export const FIRMWARE_BUNDLE_FILTERKEYS_NOT_APPLICABLE = 'Not applicable';
export const FIRMWARE_COUNTS_NOT_APPLICABLE_KEY = 'Not applicable';
export const ILO_FW_VERSION_COUNTS_UNKNOWN_KEY = 'UNKNOWN';
export const CONNECTION_TYPE_FILTERKEYS_DIRECT = 'Direct';
export const APPLIANCE_NAME_NOT_SET = '--';
export const APPLIANCE_TEMPLATE_NAME_NOT_SET = '--';
export const APPLIANCE_SETTINGS_TEMPLATE_NOT_SET = '--';
export const SERVER_NAME_NOT_SET = '--';
export const SERVER_MODEL_NOT_SET = '--';
export const SERVER_NOT_IN_GROUP = '--';
export const UNDEFINED_BASELINE_FWU_DURATION = '--';
export const GROUP_NAME_NOT_SET = '--';
export const SERVER_SETTINGS_NAME_NOT_SET = '--';
export const SERVER_SETTINGS_DESCRIPTION_NOT_SET = '--';
export const SERVER_SETTINGS_GROUPS_COUNT_NOT_SET = '--';
export const SERVER_SETTINGS_FIRMWARE_NAME_NOT_SET = '--';
export const SERVER_SETTINGS_PLATFORM_NOT_SET = '--';
export const SERVER_SETTINGS_CATEGORY_NOT_SET = '--';
export const SERVER_SETTINGS_TYPE_NOT_SET = '--';
export const SERVER_SETTINGS_RAID_TYPE_NOT_SET = '--';
export const GROUP_DESCRIPTION_NOT_SET = '--';
export const REPORT_NAME_NOT_SET = '--';
export const NOT_SET = '--';
export const HEALTH_SUMMARY_NOT_SET = '-';
export const PLATFORM_NOT_SET = '--';
export const PRODUCT_ID_NOT_SET = '--';
export const MODEL_NOT_SET = '--';
export const APPLIANCE_VERSION_NOT_SET = '--';
export const NO_ACTIVE_JOB_STATE = 'No active job in progress';
export const CUSTOMER_ADVISORY_NOT_SET = '--';
export const NONE = 'NONE';
export const FIRMWARE_UPDATE = 'Firmware update';
export const GROUP_FIRMWARE_UPDATE = 'GroupFirmwareUpdate';
export const NOT_SUPPORTED = 'Not supported';

// use to show in group detail page, if group dont have firmware baseline
export const FIRMWARE_COMPLIANCE_BASELINE_NOT_SET = 'Firmware baseline not set';

export const PAGE_SIZE = 100;

export const MAX_SERVERS_PER_GROUP = 1024;

export const PLATFORM_FAMILY_SDFLEX_280 = 'SDFlex280';
export const PLATFORM_FAMILY_PROLIANT = 'ProLiant';
export const PLATFORM_FAMILY_ALLETRA = 'Alletra';
export const PLATFORM_FAMILY_EDGELINE = 'Edgeline';

const PLATFORM_SDFLEX_280_UPPER = PLATFORM_FAMILY_SDFLEX_280.toUpperCase();
export const PLATFORM_PROLIANT_UPPER = PLATFORM_FAMILY_PROLIANT.toUpperCase();
const PLATFORM_ALLETRA_UPPER = PLATFORM_FAMILY_ALLETRA.toUpperCase();
export const PLATFORM_EDGELINE_UPPER = PLATFORM_FAMILY_EDGELINE.toUpperCase();

export const PLATFORMS_ELIGIBLE = [
  PLATFORM_PROLIANT_UPPER,
  PLATFORM_ALLETRA_UPPER,
];

export const PLATFORMS_ELIGIBLE_V2 = [
  PLATFORM_PROLIANT_UPPER,
  PLATFORM_ALLETRA_UPPER,
  PLATFORM_EDGELINE_UPPER,
];

export function platformToTitleForm(name) {
  if (name === PLATFORM_PROLIANT_UPPER) {
    return PLATFORM_FAMILY_PROLIANT;
  }
  if (name === PLATFORM_ALLETRA_UPPER) {
    return PLATFORM_FAMILY_ALLETRA;
  }
  if (name === PLATFORM_SDFLEX_280_UPPER) {
    return PLATFORM_FAMILY_SDFLEX_280;
  }
  if (name === PLATFORM_EDGELINE_UPPER) {
    return PLATFORM_FAMILY_EDGELINE;
  }

  return name;
}

export const FIRMWARE_CATEGORY = 'FIRMWARE';
export const SETTING_CATEGORY_SERVER_FIRMWARE = 'Server firmware';
export const SETTING_CATEGORY_FIRMWARE = 'Firmware';
export const SETTING_CATEGORY_SERVER_INTERNAL_STORAGE =
  'Server internal storage';
export const SETTING_CATEGORY_INTERNAL_STORAGE = 'Internal storage';
export const SETTING_CATEGORY_STORAGE = 'STORAGE';
export const SETTING_CATEGORY_OS = 'OS'; // TODO find out proper category name
export const SETTING_CATEGORY_SERVER_OS_IMAGE = 'Server operating system image';
export const SETTING_CATEGORY_OS_IMAGE = 'Operating system image';
export const SETTING_CATEGORY_BIOS = 'BIOS';
export const SETTING_CATEGORY_SERVER_BIOS = 'Server BIOS';
export const SETTING_CATEGORY_DISPLAY_NAME_BIOS = 'BIOS/Workload profile';
export const SETTING_CATEGORY_OV_APPLIANCE_SETTING =
  'OVE_APPLIANCE_SETTINGS_ANY';
export const SETTING_CATEGORY_DISPLAY_NAME_OV_APPLIANCE =
  'OneView appliance settings';
export const SETTING_CATEGORY_DISPLAY_NAME_ONEVIEW_SERVER_TEMPLATES =
  'OneView VM server templates';
export const SETTING_CATEGORY_DISPLAY_NAME_ONEVIEW_SYNERGY_SERVER_TEMPLATES =
  'OneView Synergy server templates';
export const SETTING_CATEGORY_ONEVIEW_VM_SERVER_TEMPLATES =
  'OVE_SERVER_TEMPLATES_VM';
export const SETTING_CATEGORY_ONEVIEW_SYNERGY_SERVER_TEMPLATES =
  'OVE_SERVER_TEMPLATES_SYNERGY';
export const SETTING_CATEGORY_ILO_SECURITY = 'ILO_SETTINGS';
export const SETTING_CATEGORY_DISPLAY_NAME_ILO_SECURITY = 'iLO';
export const SETTING_CATEGORY_EXTERNAL_STORAGE = 'EXTERNAL_STORAGE';
export const SETTING_CATEGORY_DISPLAY_NAME_EXTERNAL_STORAGE =
  'External storage';
export const SETTING_CATEGORY_DISPLAY_NAME_SERVER_EXTERNAL_STORAGE =
  'Server external storage';
export const SETTING_CATEGORY_OV_SYNERGY_APPLIANCE_SETTING =
  'OVE_APPLIANCE_SETTINGS_SYNERGY';
export const SETTING_CATEGORY_DISPLAY_NAME_OV_SYNERGY_APPLIANCE =
  'OneView Synergy appliance settings';
export const SETTING_CATEGORY_DISPLAY_NAME_APPLIANCE_FIRMWARE =
  'OneView VM appliance software';
export const SETTING_CATEGORY_DISPLAY_NAME_OV_SYNERGY_APPLIANCE_FIRMWARE =
  'OneView Synergy appliance software';
export const SETTING_CATEGORY_APPLIANCE_FIRMWARE = {
  VM: 'OVE_SOFTWARE_VM',
  SYNERGY: 'OVE_SOFTWARE_SYNERGY',
};
export const SETTING_CATEGORY_ILO_CREATE_DISPLAY_NAME = 'iLO settings';
export const SETTING_HPE_PREDEFINED = 'HPE pre-defined';
export const POWER_OFF_STATE = 'OFF';
export const POWER_UNKNOWN_STATE = 'UNKNOWN';
export const POWER_ON_STATE = 'ON';
export const POWER_POWERING_ON_STATE = 'POWERING_ON';
export const POWER_POWERING_OFF_STATE = 'POWERING_OFF';
export const POWER_RESET_STATE = 'RESET';

export const LED_LIT = 'LIT';
export const LED_BLINKING = 'BLINKING';
export const LED_OFF = 'OFF';
export const LED_UNKNOWN = 'UNKNOWN';

export const HEALTH_UNKNOWN = 'UNKNOWN';
export const HEALTH_OK = 'OK';
export const HEALTH_WARNING = 'WARNING';
export const HEALTH_CRITICAL = 'CRITICAL';
export const HEALTH_READY = 'READY';
export const HEALTH_NOT_PRESENT = 'NOT_PRESENT';
export const HEALTH_NON_REDUNDANT = 'NON_REDUNDANT';
export const HEALTH_FAILED_REDUNDANT = 'FAILED_REDUNDANT';
export const HEALTH_DISABLED = 'DISABLED';

export const FIRMWARE_REPORT_STATUS_OK = 'ok';
export const FIRMWARE_REPORT_STATUS_FAILED = 'failed';
export const FIRMWARE_REPORT_STATUS_SKIPPED = 'skipped';

export const OV_STATE_UNKNOWN = 'Unknown';
export const OV_STATE_ADDING = 'Adding';
export const OV_STATE_NO_PROFILE_APPLIED = 'NoProfileApplied';
export const OV_STATE_MONITORED = 'Monitored';
export const OV_STATE_UNMANAGED = 'Unmanaged';
export const OV_STATE_REMOVING = 'Removing';
export const OV_STATE_REMOVE_FAILED = 'RemoveFailed';
export const OV_STATE_REMOVED = 'Removed';
export const OV_STATE_APPLYING_PROFILE = 'ApplyingProfile';
export const OV_STATE_PROFILE_APPLIED = 'ProfileApplied';
export const OV_STATE_REMOVING_PROFILE = 'RemovingProfile';
export const OV_STATE_PROFILE_ERROR = 'ProfileError';
export const OV_STATE_UNSUPPORTED = 'Unsupported';
export const OV_STATE_UPDATING_FIRMWARE = 'UpdatingFirmware';

export const INTERNAL_ERROR = 'Internal error';

export const YES = 'Yes';

export const OV_SUBSCRIPTION_STATES = {
  STARTED: 'Started',
  ENDED: 'Ended',
  CANCELLED: 'Cancelled',
};

export const OV_APPLIANCE_TYPE = {
  VM: 'VM',
  SYNERGY: 'SYNERGY',
  GATEWAY: 'GATEWAY',
};

export const APPLIANCE_TYPE_LABEL = {
  VM: 'VM',
  SYNERGY: 'Synergy',
  GATEWAY: 'Secure gateway',
};

export const GROUP_TYPE_DISPLAY_NAME_SERVER = 'Server';
export const GROUP_TYPE_DISPLAY_NAME_VM_APPLIANCE = 'OneView VM appliance';
export const GROUP_TYPE_DISPLAY_NAME_SYNERGY_APPLIANCE =
  'OneView Synergy appliance';

export const SPT_NOT_AVAILABLE_ERROR_CODE = 'HPE-GL-COMPUTE_OPS-0500148';
export const HTTPS_NOT_SUPPORTED_ERROR_CODE = 'HPE-GL-COMPUTE_OPS-0500163';

export const GROUP_TYPE_MAP = {
  SERVER: GROUP_TYPE_DISPLAY_NAME_SERVER,
  VM_APPLIANCE: GROUP_TYPE_DISPLAY_NAME_VM_APPLIANCE,
  SYNERGY_APPLIANCE: GROUP_TYPE_DISPLAY_NAME_SYNERGY_APPLIANCE,
};

export const GROUP_PLATFORM_FAMILY_MAP = {
  APPLIANCE_VM: 'OVE_APPLIANCE_VM',
  APPLIANCE_SYNERGY: 'OVE_APPLIANCE_SYNERGY',
  APPLIANCE_ANY: 'OVE_APPLIANCE_ANY',
  ANY: 'ANY',
};

export const GROUP_PLATFORM_FAMILY_DISPLAY_NAME_MAP = {
  ANY: 'Server',
  APPLIANCE_VM: 'OneView VM appliance',
  APPLIANCE_SYNERGY: 'OneView Synergy appliance',
};

export const ILO_SUPPORTED_VERSION = [
  {
    name: 'iLO 6',
    version: 'v1.59 or later',
  },
  {
    name: 'iLO 6',
    version: 'v1.62 or later',
  },
];
export const PIN_BASED_ILO_SUPPORTED_VERSION = [
  {
    name: 'iLO 6',
    version: 'v1.64 or later',
  },
  {
    name: 'iLO 5',
    version: 'v3.09 or later',
  },
];

export const GROUP_DEVICE_TYPE_MAP = {
  APPLIANCE_VM: 'OVE_APPLIANCE_VM',
  APPLIANCE_SYNERGY: 'OVE_APPLIANCE_SYNERGY',
  DIRECT_CONNECT_SERVER: 'DIRECT_CONNECT_SERVER',
};

export function groupPlatformFamilyToDeviceType(name) {
  if (name === GROUP_PLATFORM_FAMILY_MAP.ANY) {
    return GROUP_DEVICE_TYPE_MAP.DIRECT_CONNECT_SERVER;
  }
  if (name === GROUP_PLATFORM_FAMILY_MAP.APPLIANCE_VM) {
    return GROUP_DEVICE_TYPE_MAP.APPLIANCE_VM;
  }
  if (name === GROUP_PLATFORM_FAMILY_MAP.APPLIANCE_SYNERGY) {
    return GROUP_DEVICE_TYPE_MAP.APPLIANCE_SYNERGY;
  }
  return null;
}

export function groupTitleToPlatformFamily(name) {
  if (name === GROUP_PLATFORM_FAMILY_DISPLAY_NAME_MAP.ANY) {
    return GROUP_PLATFORM_FAMILY_MAP.ANY;
  }
  if (name === GROUP_PLATFORM_FAMILY_DISPLAY_NAME_MAP.APPLIANCE_VM) {
    return GROUP_PLATFORM_FAMILY_MAP.APPLIANCE_VM;
  }
  if (name === GROUP_PLATFORM_FAMILY_DISPLAY_NAME_MAP.APPLIANCE_SYNERGY) {
    return GROUP_PLATFORM_FAMILY_MAP.APPLIANCE_SYNERGY;
  }

  return name;
}

export function groupPlatformFamilyToTitleForm(name) {
  if (name === GROUP_PLATFORM_FAMILY_MAP.ANY) {
    return GROUP_PLATFORM_FAMILY_DISPLAY_NAME_MAP.ANY;
  }
  if (name === GROUP_PLATFORM_FAMILY_MAP.APPLIANCE_VM) {
    return GROUP_PLATFORM_FAMILY_DISPLAY_NAME_MAP.APPLIANCE_VM;
  }
  if (name === GROUP_PLATFORM_FAMILY_MAP.APPLIANCE_SYNERGY) {
    return GROUP_PLATFORM_FAMILY_DISPLAY_NAME_MAP.APPLIANCE_SYNERGY;
  }

  return name;
}

export const GROUP_SECURITY_STATUS_MAP = {
  RISK: 'RISK',
  IGNORED: 'IGNORED',
  OK: 'OK',
  UNKNOWN: 'UNKNOWN',
  UNSUPPORTED: 'UNSUPPORTED',
};

export const GROUP_SECURITY_STATUS_DISPLAY_NAME_MAP = {
  RISK: 'At risk',
  IGNORED: 'Ignore risk',
  OK: 'Ok',
  UNKNOWN: 'Unknown',
  UNSUPPORTED: 'Unsupported',
};

export function groupSecurityStatusToTitleForm(name) {
  if (name === GROUP_SECURITY_STATUS_MAP.RISK) {
    return GROUP_SECURITY_STATUS_DISPLAY_NAME_MAP.RISK;
  }
  if (name === GROUP_SECURITY_STATUS_MAP.IGNORED) {
    return GROUP_SECURITY_STATUS_DISPLAY_NAME_MAP.IGNORED;
  }
  if (name === GROUP_SECURITY_STATUS_MAP.OK) {
    return GROUP_SECURITY_STATUS_DISPLAY_NAME_MAP.OK;
  }
  if (name === GROUP_SECURITY_STATUS_MAP.UNKNOWN) {
    return GROUP_SECURITY_STATUS_DISPLAY_NAME_MAP.UNKNOWN;
  }
  if (name === GROUP_SECURITY_STATUS_MAP.UNSUPPORTED) {
    return GROUP_SECURITY_STATUS_DISPLAY_NAME_MAP.UNSUPPORTED;
  }

  return name;
}

export const SETTING_CATEGORY_MAP = {
  FIRMWARE: SETTING_CATEGORY_SERVER_FIRMWARE,
  INTERNAL_STORAGE: SETTING_CATEGORY_SERVER_INTERNAL_STORAGE,
  OS_IMAGE: SETTING_CATEGORY_SERVER_OS_IMAGE,
  EXTERNAL_STORAGE: SETTING_CATEGORY_DISPLAY_NAME_SERVER_EXTERNAL_STORAGE,
  BIOS: SETTING_CATEGORY_DISPLAY_NAME_BIOS,
  OV_APPLIANCE_SETTING: SETTING_CATEGORY_DISPLAY_NAME_OV_APPLIANCE,
  OV_SYNERGY_APPLIANCE_SETTING:
    SETTING_CATEGORY_DISPLAY_NAME_OV_SYNERGY_APPLIANCE,
  OV_SERVER_TEMPLATE: SETTING_CATEGORY_DISPLAY_NAME_ONEVIEW_SERVER_TEMPLATES,
  OVE_SOFTWARE_VM: SETTING_CATEGORY_DISPLAY_NAME_APPLIANCE_FIRMWARE,
  OVE_SOFTWARE_SYNERGY:
    SETTING_CATEGORY_DISPLAY_NAME_OV_SYNERGY_APPLIANCE_FIRMWARE,
  ILO_SETTINGS: SETTING_CATEGORY_ILO_CREATE_DISPLAY_NAME,
  OV_SERVER_TEMPLATE_SYNERGY:
    SETTING_CATEGORY_DISPLAY_NAME_ONEVIEW_SYNERGY_SERVER_TEMPLATES,
};

export const SETTING_DETAIL_CATEGORY_MAP = {
  FIRMWARE: SETTING_CATEGORY_SERVER_FIRMWARE,
  STORAGE: SETTING_CATEGORY_SERVER_INTERNAL_STORAGE,
  BIOS: SETTING_CATEGORY_DISPLAY_NAME_BIOS,
  OS: SETTING_CATEGORY_SERVER_OS_IMAGE,
  ILO_SETTINGS: SETTING_CATEGORY_DISPLAY_NAME_ILO_SECURITY,
  OVE_SERVER_TEMPLATES_VM:
    SETTING_CATEGORY_DISPLAY_NAME_ONEVIEW_SERVER_TEMPLATES,
  OVE_SERVER_TEMPLATES_SYNERGY:
    SETTING_CATEGORY_DISPLAY_NAME_ONEVIEW_SYNERGY_SERVER_TEMPLATES,
  EXTERNAL_STORAGE: SETTING_CATEGORY_DISPLAY_NAME_SERVER_EXTERNAL_STORAGE,
  OVE_SOFTWARE_VM: SETTING_CATEGORY_DISPLAY_NAME_APPLIANCE_FIRMWARE,
  OVE_SOFTWARE_SYNERGY:
    SETTING_CATEGORY_DISPLAY_NAME_OV_SYNERGY_APPLIANCE_FIRMWARE,
  OVE_APPLIANCE_SETTINGS_ANY: SETTING_CATEGORY_DISPLAY_NAME_OV_APPLIANCE,
  OVE_APPLIANCE_SETTINGS_SYNERGY:
    SETTING_CATEGORY_DISPLAY_NAME_OV_SYNERGY_APPLIANCE,
};

export const SETTING_DETAIL_CATEGORY_ORDER = {
  FIRMWARE: 1,
  STORAGE: 2,
  BIOS: 3,
  OS: 4,
  ILO_SETTINGS: 5,
  OVE_SOFTWARE_VM: 6,
  OVE_SOFTWARE_SYNERGY: 7,
  OVE_APPLIANCE_SETTINGS_ANY: 8,
  OVE_APPLIANCE_SETTINGS_SYNERGY: 9,
  OVE_SERVER_TEMPLATES_VM: 10,
};

export const GEN_8_DISPLAY_VALUE = 'Gen8';
export const GEN_9_DISPLAY_VALUE = 'Gen9';
export const GEN_10_DISPLAY_VALUE = 'Gen10/+';
export const GEN_11_DISPLAY_VALUE = 'Gen11';
export const GEN_12_DISPLAY_VALUE = 'Gen12';
export const EDGELINE_GEN_10_DISPLAY_VALUE = 'Edgeline';

export const GROUP_FW_SETTING_GEN10 = 'GEN10';
export const GROUP_FW_SETTING_GEN11 = 'GEN11';
export const GROUP_FW_SETTING_GEN12 = 'GEN12';
export const GROUP_FW_SETTING_EDGELINE_GEN10 = 'EDGELINE_GEN10';
export const GEN_8 = 'GEN_8';
export const GEN_9 = 'GEN_9';
export const GEN_10 = 'GEN_10';
export const GEN_11 = 'GEN_11';
export const GEN_12 = 'GEN_12';
export const EDGELINE_GEN_10 = 'EDGELINE_GEN_10';
export const OV_PREFIX_DEVICE_ID = 'oneview+';
export const OV_APPLIANCE = 'oneview';
export const OV_SYNERGY_APPLIANCE = 'oneview_synergy';
export const GEN_UNKNOWN = 'UNKNOWN';
export const SETTING_GENERATION_GEN10 = GEN_10_DISPLAY_VALUE;
export const SETTING_GENERATION_GEN11 = GEN_11_DISPLAY_VALUE;
export const SETTING_GENERATION_GEN12 = GEN_12_DISPLAY_VALUE;
export const SETTING_GENERATION_EDGELINE_GEN10 = 'Edgeline Gen10/+';

export const PLATFORM_SETTING_GENERATION_GEN10 = 'ProLiant Gen10/+';
export const PLATFORM_SETTING_GENERATION_GEN11 = 'ProLiant and Alletra Gen11';
export const PLATFORM_SETTING_GENERATION_GEN12 = 'ProLiant and Alletra Gen12';

export const APPLIANCE_SETTING_NTP_CONFIG = 'APPLIANCE';
export const MINIMUM_APPLIANCE_VERSION = 8.6;
export const MIN_APPLIANCE_VERSION_FOR_APPLIANCE_SETTINGS = 8.9;
export const MIN_APPLIANCE_VERSION_FOR_ENHANCED_SECURITY_SETTINGS = 9.3;
export const MIN_APPLIANCE_VERSION_FOR_SYNERGY_TEMPLATES_SETTINGS = 9.3;

export const SERVER_GEN_TO_DISPLAY_GEN_MAP = {
  [GEN_8]: GEN_8_DISPLAY_VALUE,
  [GEN_9]: GEN_9_DISPLAY_VALUE,
  [GEN_10]: GEN_10_DISPLAY_VALUE,
  [GEN_11]: GEN_11_DISPLAY_VALUE,
  [GEN_12]: GEN_12_DISPLAY_VALUE,
};

export const SETTING_GENERATION_MAP = {
  [GROUP_FW_SETTING_GEN10]: SETTING_GENERATION_GEN10,
  [GROUP_FW_SETTING_GEN11]: SETTING_GENERATION_GEN11,
  [GROUP_FW_SETTING_GEN12]: SETTING_GENERATION_GEN12,
};

export const PLATFORM_SETTING_GENERATION_MAP = {
  [GROUP_FW_SETTING_GEN10]: PLATFORM_SETTING_GENERATION_GEN10,
  [GROUP_FW_SETTING_GEN11]: PLATFORM_SETTING_GENERATION_GEN11,
  [GROUP_FW_SETTING_GEN12]: PLATFORM_SETTING_GENERATION_GEN12,
  [GROUP_FW_SETTING_EDGELINE_GEN10]: SETTING_GENERATION_EDGELINE_GEN10,
};
export const OPERATION_CREATE = 'Create';
export const OPERATION_UPDATE = 'Update';

// Width of view server hardware inventory wizard columns in pixels
export const VIEW_HW_INV_COLUMN_WIDTH = 650;
// Width of name value pair for appliance settings accordion view in px
export const APPLIANCE_SETTINGS_NAME_VALUE_PAIR_WIDTH = 280;

// Width of view server network connectivity wizard columns in pixels
export const VIEW_NW_CONNECTIVITY_COLUMN_WIDTH = 650;

// Used to map the new crossroads keys for health data
// and the corresponding alert category key.
export const ALERT_TO_SERVER_HEALTH_MAP = {
  fans: 'fans',
  fanRedundancy: 'fanRedundancy',
  liquidCoolingModule: 'liquidCooling',
  liquidCoolingModuleRedundancy: 'liquidCoolingRedundancy',
  temperature: 'temperature',
  powerSupplies: 'powerSupplies',
  powerSupplyRedundancy: 'powerSupplyRedundancy',
  processor: 'processor',
  memory: 'memory',
  storage: 'storage',
  smartStorageBattery: 'smartStorage',
  network: 'network',
  bios: 'bios',
};

export const SUBSCRIPTION_TIER_MAP = {
  STANDARD: 'Standard',
  ENHANCED: 'Enhanced',
  ADVANCED: 'Advanced',
};

export const SUBSCRIPTION_STATES = {
  SUBSCRIBED: 'SUBSCRIBED',
  REQUIRED: 'REQUIRED',
  EXPIRED: 'EXPIRED',
};

export const SECURE_GATEWAY = 'Secure gateway';
export const ONE_VIEW = 'OneView';
export const DIRECT_CONNECT = 'Direct connect';

export const SECURE_GATEWAY_APPLIANCE = 'Secure gateway appliance';
export const ONE_VIEW_APPLIANCE = 'OneView Appliance';

export const GATEWAY_APPLIANCE_REMOVE_KEY = 'GW_APPLIANCE_REMOVE';
export const ONE_VIEW_APPLIANCE_REMOVE_KEY = 'APPLIANCE_REMOVE';

export const CONNECTION_TYPE = {
  DIRECT_CONNECT: 'Direct',
  SECURE_GATEWAY: 'Secure gateway',
};

export const APPLIANCE_TYPE_OPTIONS = [ONE_VIEW, SECURE_GATEWAY];
export const CONNECTION_TYPE_OPTIONS = [DIRECT_CONNECT, SECURE_GATEWAY];

export const SCHEDULE_JOB_URL = '/api/compute/v1/jobs';
export const JOB_TEMPLATE_BASE_URL = '/api/compute/v1/job-templates';
export const JOB_TEMPLATE_V1B2_URL = '/api/compute-ops/v1beta2/job-templates';
export const WAIT_FOR_REBOOT_DOC_URL =
  'https://support.hpe.com/hpesc/public/docDisplay?docId=sd00001293en_us&page=cc_onboard_compute.html';

// eslint-disable-next-line max-len
export const FIRMWARE_UPDATE_TEMPLATE_URI = `${JOB_TEMPLATE_BASE_URL}/fd54a96c-cabc-42e3-aee3-374a2d009dba`;
// eslint-disable-next-line max-len
export const POWER_OFF_TEMPLATE_URI = `${JOB_TEMPLATE_BASE_URL}/d0c13b58-748c-461f-9a61-c0c5c71f1bb4`;
// eslint-disable-next-line max-len
export const POWER_ON_TEMPLATE_URI = `${JOB_TEMPLATE_BASE_URL}/0cbb2377-1834-488d-840c-d5bf788c34fb`;
// eslint-disable-next-line max-len
export const COLD_BOOT_TEMPLATE_URI = `${JOB_TEMPLATE_BASE_URL}/aacfb3e0-6575-4d4f-a711-1ee1ae768407`;
export const RESET_TEMPLATE_URI = `${JOB_TEMPLATE_BASE_URL}/30110551-cad6-4069-95b8-dbce9bbd8525`;
// eslint-disable-next-line max-len
export const FIRMWARE_BATCH_UPDATE_TEMPLATE_URI = `${JOB_TEMPLATE_BASE_URL}/91159b5e-9eeb-11ec-a9da-00155dc0a0c0`;
// eslint-disable-next-line max-len
export const APPLIANCE_BATCH_UPDATE_TEMPLATE_URI = `${JOB_TEMPLATE_V1B2_URL}/f69f553a-5004-4a08-9283-5b60abd9eb4a`;
// eslint-disable-next-line max-len
export const UPDATE_ILO_FIRMWARE_TEMPLATE_URI = `${JOB_TEMPLATE_BASE_URL}/eeff382e-b0de-4cf9-b8a7-e142e88b9668`;
// eslint-disable-next-line max-len
export const UPDATE_ILO_FIRMWARE_SERVER_TEMPLATE_URI = `${JOB_TEMPLATE_BASE_URL}/94caa4ef-9ff8-4805-9e97-18a09e673b66`;
// eslint-disable-next-line max-len
export const FIRMWARE_COMPLIANCE_TEMPLATE_URI = `${JOB_TEMPLATE_BASE_URL}/23b8ba2a-6c46-4223-b028-919382c7dcac`;
// eslint-disable-next-line max-len
export const EXTERNAL_STORAGE_COMPLIANCE_TEMPLATE_URI = `${JOB_TEMPLATE_BASE_URL}/7177aa6a-e8f8-4e9b-ae31-e01dafcc81df`;
// eslint-disable-next-line max-len
export const ILO_SETTINGS_COMPLIANCE_TEMPLATE_URI = `${JOB_TEMPLATE_BASE_URL}/a55c8b26-3c57-4044-a4ee-1d0e3c108286`;
// eslint-disable-next-line max-len
export const APPLY_INTERNAL_STORAGE_CONFIG_TEMPLATE_URI = `${JOB_TEMPLATE_BASE_URL}/c708eb57-235d-4ea8-9e21-8ceea2438773`;
// eslint-disable-next-line max-len
export const APPLY_INTERNAL_STORAGE_CONFIG_TEMPLATE_FOR_MULTI_VOL_URI = `${JOB_TEMPLATE_BASE_URL}/54095626-3911-4fea-9741-816e2531994e`;
// eslint-disable-next-line max-len
export const APPLY_EXTERNAL_STORAGE_CONFIG_TEMPLATE_URI = `${JOB_TEMPLATE_BASE_URL}/fcb79270-5954-42e9-9374-6a065b6d494a`;
// eslint-disable-next-line max-len
export const ILO_WEEKLY_CARBON_REPORT_TEMPLATE_URI = `${JOB_TEMPLATE_BASE_URL}/b0001d36-6490-48ac-93af-a87adfb997ed`;
// eslint-disable-next-line max-len
export const APPLY_SERVER_SETTINGS_TEMPLATE_URI = `${JOB_TEMPLATE_BASE_URL}/beff07ce-f36d-4699-9ac3-f872dcd63133`;
// eslint-disable-next-line max-len
export const INSTALL_OS_IMAGE_TEMPLATE_URI = `${JOB_TEMPLATE_BASE_URL}/e2952628-2629-4088-93db-91742304ef0c`;
// eslint-disable-next-line max-len
export const OV_HARDWARE_INVENTORY_TEMPLATE_URI = `${JOB_TEMPLATE_BASE_URL}/a0124cb1-00f1-46b7-818f-a9eb5f60591f`;
// Uri is not available, will change it accordingly
// eslint-disable-next-line max-len
export const SH_INVENTORY_REPORT_TEMPLATE_URI = `${JOB_TEMPLATE_BASE_URL}/temp-server-hw-inventory-report-template-uri`;
// eslint-disable-next-line max-len
export const SCOMPONENTS_INVENTORY_REPORT_TEMPLATE_URI = `${JOB_TEMPLATE_BASE_URL}/temp-server-hw-inventory-report-template-uri`;
// eslint-disable-next-line max-len
export const SERVER_FWCOMPLIANCE_REPORT_TEMPLATE_URI = `${JOB_TEMPLATE_BASE_URL}/temp-server-hw-inventory-report-template-uri`;
// eslint-disable-next-line max-len
export const SERVER_INVENTORY_TEMPLATE_URI = `${JOB_TEMPLATE_V1B2_URL}/d6595f1b-84e6-4587-ade5-656e2a5ea20d`;
// eslint-disable-next-line max-len
export const IGNORE_ILO_SECURITY_SETTINGS_TEMPLATE_URI = `${JOB_TEMPLATE_V1B2_URL}/e1d69e76-38cc-4079-9192-a380baea2973`;
// eslint-disable-next-line max-len
export const NETWORK_CONNECTIVITY_TEMPLATE_URI = `${JOB_TEMPLATE_BASE_URL}/b21ca9e2-8a1b-11ee-b9d1-0242ac120002`;
// eslint-disable-next-line max-len
export const OV_FIRMWARE_UPDATE_TEMPLATE_URI = `${JOB_TEMPLATE_V1B2_URL}/1c4ac4be-8eeb-49f2-a86a-fd8c9182616c`;
// eslint-disable-next-line max-len
export const OV_SERVER_TEMPLATE_UPDATE_URI = `${JOB_TEMPLATE_V1B2_URL}/abfda355-6e58-4c00-be0a-af35dbd70398`;
// eslint-disable-next-line max-len
export const COPY_OV_SERVER_TEMPLATE_URI = `${JOB_TEMPLATE_BASE_URL}/db3620d4-19a4-4b54-9804-83f8f59d48a4`;
// eslint-disable-next-line max-len
export const SERVER_VOLUME_DETAILS_TEMPALTE_URI = `${JOB_TEMPLATE_V1B2_URL}/9310319e-7b7f-41ba-8b24-8b34eed1ca62`;
// eslint-disable-next-line max-len
export const APPLY_OV_APPLIANCE_SETTINGS_TEMPLATE_URI = `${JOB_TEMPLATE_V1B2_URL}/a229a162-b43f-45b0-b7bb-692df77b9746`;
// eslint-disable-next-line max-len
export const REFRESH_APPLIANCE_SETTINGS_TEMPLATE_URI = `${JOB_TEMPLATE_V1B2_URL}/fc16aa48-c73c-4463-9112-e061383ebfa9`;
export const REBOOT_TEMPLATE_URI = `${JOB_TEMPLATE_V1B2_URL}/91afe709-8b0d-4162-81d5-2a365e0359f3`;
// eslint-disable-next-line max-len
export const REMOTE_CONSOLE_TEMPLATE_URI = `${JOB_TEMPLATE_BASE_URL}/2c7de503-77af-4340-b68d-7a26e5359b8e`;

export const JOB_NAME_URI_MAP = {
  [FIRMWARE_UPDATE_TEMPLATE_URI]: 'FirmwareUpdate',
  [POWER_OFF_TEMPLATE_URI]: 'PowerOff',
  [POWER_ON_TEMPLATE_URI]: 'PowerOn',
  [RESET_TEMPLATE_URI]: 'Restart',
};

// Max width for cards
export const OVERVIEW_AND_CUSTOMERS_PAGE_CARD_WIDTH = '450px';
export const DEVICES_AND_GROUPS_PAGE_CARD_WIDTH = '525px';

// Conditions that cause a server to be disabled
export const DISABLED_CONDITIONS = {
  NOT_ASSIGNED: 'NOT_ASSIGNED',
  NOT_ACTIVATED: 'NOT_ACTIVATED',
  NOT_CONNECTED: 'NOT_CONNECTED',
  SUBSCRIPTION_REQUIRED: 'SUBSCRIPTION_REQUIRED',
  SUBSCRIPTION_EXPIRED: 'SUBSCRIPTION_EXPIRED',
};

// Firmware update scheduling options
export const UPDATE_FW_NOW = 'Update immediately';
export const UPDATE_FW_LATER = 'Schedule the update';

export const INSTALL_OS_NOW = 'Install immediately';
export const SCHEDULE_OS_LATER = 'Schedule the installation';

// iLO Firmware update scheduling
export const SERVER_ILO_FW_UPDATE = 'SERVER_ILO_FW_UPDATE';

// Server level firmware update scheduling
export const SERVER_FW_UPDATE = 'SERVER_FW_UPDATE';

// server state used to allow action
export const CONNECTIVITY_STATES = ['Connected', 'Reconnecting'];

// Subscription URL
export const COM_OPS_URL = 'https://hpe.com/info/compute-ops-mgmt';

// CoM Instance Time
export const COM_INSTANCE_TIME_WINDOW = { start: '20:00', end: '05:00' };

export const MINIMUM_ILO5_VERSION = '2.72';
export const MINIMUM_ILO5_VERSION_FLOAT = parseFloat(MINIMUM_ILO5_VERSION);
export const MINIMUM_ILO6_VERSION = '1.30';
export const MINIMUM_ILO6_VERSION_FLOAT = parseFloat(MINIMUM_ILO6_VERSION);
export const MINIMUM_ILO5_SECURITY_VERSION = '3.0';
export const MINIMUM_ILO6_SECURITY_VERSION = '1.55';
export const MINIMUM_ILO5_SSO_VERSION = '3.06';
export const MINIMUM_ILO6_SSO_VERSION = '1.61';
export const MINIMUM_ILO5_SSO_VERSION_FLOAT = parseFloat(
  MINIMUM_ILO5_SSO_VERSION,
);
export const MINIMUM_ILO6_SSO_VERSION_FLOAT = parseFloat(
  MINIMUM_ILO6_SSO_VERSION,
);
export const ILO5 = 'iLO 5';
export const ILO6 = 'iLO 6';
export const APOSTROPHE = "'";

// Compliance Status Mapping
export const COMPLIANCE_STATUS_MAP = {
  COMPLIANT: 'Compliant',
  NOT_COMPLIANT: 'Not compliant',
  UNKNOWN: 'Unknown',
  NOT_APPLICABLE: 'Not applicable',
};

export const AUTO_ADD_TAG_SEPARATOR_CHAR = ';';

// OS image configuration options

export const OS_TYPES = {
  VMWARE_ESXI: 'VMware ESXi',
  MICROSOFT_WINDOWS: 'Microsoft Windows',
  RHEL: 'RHEL',
  SUSE_LINUX: 'SUSE Linux',
  UBUNTU_LINUX: 'Ubuntu Linux',
  CUSTOM: 'Custom',
};

// External storage host os options
export const HOST_OS_TYPES = {
  AIX: 'AIX',
  APPLE: 'Apple',
  CITRIX_HYPERVISOR: 'Citrix Hypervisor',
  HP_UX: 'HP-UX',
  IBM_VIO_SERVER: 'IBM VIO Server',
  IN_FORM: 'InForm',
  NETAPP_ONTAP: 'NetApp ONTAP',
  OE_LINUX_UEK: 'OE Linux UEK',
  OPEN_VMS: 'OpenVMS',
  ORACLE_VM_X86: 'Oracle VM x86',
  RHE_LINUX: 'RHE Linux',
  RHE_VIRTUALIZATION: 'RHE Virtualization',
  SOLARIS: 'Solaris',
  SUSE_LINUX: 'SUSE Linux',
  SUSE_VIRTUALIZATION: 'SUSE Virtualization',
  UBUNTU: 'Ubuntu',
  VMWARE_ESXI: 'Vmware ESXi',
  WINDOWS_SERVER: 'Windows Server',
};

// Internal storage configuration options
export const RAID_TYPES = ['RAID0', 'RAID1', 'RAID5'];
export const DRIVE_TECHNOLOGY = [
  'Not specified',
  'NVMe SSD',
  'SAS HDD',
  'SAS SSD',
  'SATA HDD',
  'SATASSD',
];
export const IO_PERFORMANCE_MODE = {
  NOT_MANAGED: 'Not managed',
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
};
export const READ_CACHE_POLICY = {
  NOT_MANAGED: 'Not managed',
  READ_AHEAD: 'Read ahead',
  ADAPTIVE_READ_AHEAD: 'Adaptive read ahead',
  OFF: 'Off',
};
export const WRITE_CACHE_POLICY = {
  NOT_MANAGED: 'Not managed',
  WRITE_THROUGH: 'Write through',
  PROTECTED_WRITE_BACK: 'Protected write back',
  UNPROTECTED_WRITE_BACK: 'Unprotected write back',
  OFF: 'Off',
};

export const USE_ENTIRE_DISK = 'Use the entire disk';
export const SPECIFY_VOLUME_SIZE = 'Specify OS volume size';
export const SPECIFY_MULTI_VOLUME_SIZE = 'Specify volume size';
export const MAX_DRIVE_COUNT = 32;
export const RAID_DRIVE_TECHNOLOGY = {
  NOT_SPECIFIED: 'Not specified',
  SAS_HDD: 'SAS HDD',
  SAS_SSD: 'SAS SSD',
  SATA_HDD: 'SATA HDD',
  SATA_SSD: 'SATA SSD',
  NVME_SSD: 'NVMe SSD',
};
export const MIN_DRIVE_FOR_RAID_TYPES = {
  RAID0: {
    display_name: 'RAID 0',
    min: 1,
    step: 1,
  },
  RAID1: {
    display_name: 'RAID 1',
    min: 2,
    step: 0,
  },
  RAID10: {
    display_name: 'RAID 10',
    min: 4,
    step: 2,
  },
  RAID1_TRIPLE: {
    display_name: 'RAID 1 Triple',
    min: 3,
    step: 0,
  },
  RAID10_TRIPLE: {
    display_name: 'RAID 10 Triple',
    min: 6,
    step: 3,
  },
  RAID5: {
    display_name: 'RAID 5',
    min: 3,
    step: 1,
  },
  RAID50: {
    display_name: 'RAID 50',
    min: 6,
    step: 2,
  },
  RAID6: {
    display_name: 'RAID 6',
    min: 4,
    step: 1,
  },
  RAID60: {
    display_name: 'RAID 60',
    min: 8,
    step: 2,
  },
};

export const ACTION_MODAL_STEPS = {
  FETCH: 'fetch',
  FETCH_ERROR: 'fetchError',
  NOOP: 'noop',
  SCHEDULES: 'schedules',
  PREFLIGHT: 'preflight',
  REVIEW: 'review',
  CONFIRMED: 'confirmed',
  FEEDBACK: 'feedback',
  CONFIGURE: 'configure',
};
export const DO_NOT_APPLY_HOTFIX = 'Do not apply a hotfix';
export const DO_NOT_APPLY_PATCH = 'Do not apply a patch';
export const NO_HOTFIXES_AVAILABLE = 'No hotfixes available';
export const NO_PATCH_BUNDLES_AVAILABLE = 'No patch bundles available';
export const BUNDLE_4OCTET = 'BUNDLE_4OCTET';
export const BUNDLE_3OCTET = 'BUNDLE_3OCTET';
export const BUNDLE_GEN_10 = 'BUNDLE_GEN_10';
export const BUNDLE_GEN_11 = 'BUNDLE_GEN_11';
export const BUNDLE_GEN_12 = 'BUNDLE_GEN_12';
export const PLATFORM_EDGELINE = 'EDGELINE';
export const BUNDLE_OV = 'BUNDLE_OV';
export const BUNDLE_OV_SYNERGY = 'BUNDLE_OV_SYNERGY';
export const BUNDLE_EDGELINE = 'EDGELINE';
export const BUNDLE_PROLIANT = 'PROLIANT';

export const BUNDLE_GENERATION_GEN10 = GEN_10_DISPLAY_VALUE;
export const BUNDLE_GENERATION_GEN11 = GEN_11_DISPLAY_VALUE;
export const BUNDLE_GENERATION_GEN12 = GEN_12_DISPLAY_VALUE;

// user facing terminology for the bundle generation
export const BUNDLE_GENERATION_MAP = {
  [BUNDLE_GEN_10]: BUNDLE_GENERATION_GEN10,
  [BUNDLE_GEN_11]: BUNDLE_GENERATION_GEN11,
  [BUNDLE_GEN_12]: BUNDLE_GENERATION_GEN12,
};

export const BUNDLE_PLATFORM_MAP = {
  [PLATFORM_EDGELINE]: EDGELINE_GEN_10_DISPLAY_VALUE,
};

// map between the server generation attribute and the bundle generation attribute
export const SERVER_GEN_TO_BUNDLE_GEN_MAP = {
  [GEN_10]: BUNDLE_GEN_10,
  [GEN_11]: BUNDLE_GEN_11,
  [GEN_12]: BUNDLE_GEN_12,
};

export const BUNDLE_GEN_TO_SERVER_GEN_MAP = {
  [BUNDLE_GEN_10]: GEN_10,
  [BUNDLE_GEN_11]: GEN_11,
  [BUNDLE_GEN_12]: GEN_12,
};

// map between the server generation attribute and the group server setting key
export const GROUP_FW_SETTING_GEN_TO_SERVER_GEN_MAP = {
  [GROUP_FW_SETTING_GEN10]: GEN_10,
  [GROUP_FW_SETTING_GEN11]: GEN_11,
  [GROUP_FW_SETTING_GEN12]: GEN_12,
};

export const SERVER_GEN_TO_GROUP_FW_SETTING_GEN_MAP = {
  [GEN_10]: GROUP_FW_SETTING_GEN10,
  [GEN_11]: GROUP_FW_SETTING_GEN11,
  [GEN_12]: GROUP_FW_SETTING_GEN12,
};

// This map is used to populate the labels in the dynamic server
// generation filter on the servers page.
// This map is also used to populate generation column in AutoIloFwUpdateModal
export const SERVER_GEN_MAP = {
  [GEN_10]: GEN_10_DISPLAY_VALUE,
  [GEN_11]: GEN_11_DISPLAY_VALUE,
  [GEN_12]: GEN_12_DISPLAY_VALUE,
  [GEN_8]: GEN_8_DISPLAY_VALUE,
  [GEN_9]: GEN_9_DISPLAY_VALUE,
  [GEN_UNKNOWN]: 'Unknown',
};

// Values of the Vulcan firmware-bundle type property shifted to lower case
export const BASELINE_TYPE = 'base';
export const HOTFIX_TYPE = 'hotfix';
export const PATCH_TYPE = 'patch';

// Report details values
export const NO_DATA = 'No data';
export const PARTIAL_DATA = 'Calculated with partial data';
export const SUCCESS_DATA = 'Calculated with complete data';

export const REPORT_REASON_MAP = {
  SUBSCRIPTION_REQ: 'Subscription required',
  SUBSCRIPTION_EXPIRED: 'Subscription expired',
  SUBSCRIPTION_TIER: 'Enhanced subscription required',
  NOT_CONNECTED: 'Server not connected',
  ILO_COMM_ERROR: 'Communication error with the server',
  INTERNAL_ERROR: 'Internal error occurred',
  UNKNOWN: 'Incomplete data',
  SUCCESS: 'Calculated carbon emissions',
  ILO_VERSION: 'Firmware update required',
  NO_POWER_METERING: 'No power metering',
};

export const REPORT_VIEW_MAP = {
  SERVER: 'Server',
  SERVER_MODEL: 'Model',
  SERVER_GENERATION: 'Generation',
  SERVER_PROCESSOR_VENDOR: 'Processor vendor',
  SERVER_LOCATION: 'Location',
};

export const REPORT_AGGREGATE_BY_MAP = {
  SERVER: 'Server',
  SERVER_MODEL: 'Model',
  SERVER_GENERATION: 'Generation',
  SERVER_PROCESSOR: 'Processor',
  SERVER_LOCATION: 'Location',
};

export const REPORT_TYPE_MAP = {
  CARBON_EMISSIONS: 'Carbon emissions',
  ENERGY_CONSUMPTION: 'Energy consumption',
  ENERGY_COST: 'Energy cost',
};

export const ANALYTICS_TYPE_MAP = {
  CARBON_EMISSIONS: 'Estimated carbon emissions - CO2e',
  ENERGY_CONSUMPTION: 'Energy consumption - Wh',
  ENERGY_COST: 'Estimated cost - $ USD',
};

export const HISTORIC_GRAPH_KEY_MAP = {
  CARBON_EMISSIONS: 'co2Emission',
  ENERGY_CONSUMPTION: 'energyConsumption',
  ENERGY_COST: 'energyCost',
};

export const SUSTAINABILITY_GRAPH_KEY_MAP = {
  CARBON_EMISSIONS: 'co2eKg',
  ENERGY_CONSUMPTION: 'kwh',
  ENERGY_COST: 'cost',
};

export const COST_UNIT = 'USD';

export const PROJECTED_ENERGY_GRAPH_KEY_MAP = {
  RANGE: 'Energyrange',
  HISTORIC_VALUE: 'historicEnergyvalue',
  PROJECTED_VALUE: 'projectedEnergyvalue',
  HISTORIC_AVG: 'historicenergyavg',
  PROJECTED_AVG: 'projectedenergyavg',
};

export const PROJECTED_COST_GRAPH_KEY_MAP = {
  RANGE: 'Costrange',
  HISTORIC_VALUE: 'historicCostvalue',
  PROJECTED_VALUE: 'projectedCostvalue',
  HISTORIC_AVG: 'historiccostavg',
  PROJECTED_AVG: 'projectedcostavg',
};

export const PROJECTED_EMISSION_GRAPH_KEY_MAP = {
  RANGE: 'Emissionrange',
  HISTORIC_VALUE: 'historicEmissionvalue',
  PROJECTED_VALUE: 'projectedEmissionvalue',
  HISTORIC_AVG: 'historicemissionavg',
  PROJECTED_AVG: 'projectedemissionavg',
};

export const REPORT_SERVER_HW_INVENTORY_REASON_MAP = {
  SUBSCRIPTION_REQ: 'Subscription required',
  SUBSCRIPTION_EXPIRED: 'Subscription expired',
  SUBSCRIPTION_TIER: 'Enhanced subscription required',
  NOT_CONNECTED: 'Server not connected',
  ILO_COMM_ERROR: 'Communication error with the server',
  INTERNAL_ERROR,
  UNKNOWN: 'Incomplete data',
  ANOTHER_JOB_IN_PROGRESS: 'Collection skipped as another job was in progress',
  ANOTHER_JOB_STALLED: 'Collection skipped as another job was stalled',
  SUCCESS: 'Inventory collected',
  INVENTORY_COLLECTED: 'Inventory collected',
  ILO_VERSION: 'Firmware update required',
  NO_POWER_METERING: 'No powering metering',
  PARTIAL_INVENTORY: 'Partial inventory collected as server was powered off',
  EMPTY_RESPONSE: INTERNAL_ERROR,
  EMPTY_PAYLOAD: INTERNAL_ERROR,
  EMPTY_REDFISH_SUBRESOURCE: INTERNAL_ERROR,
  RETRIES_EXHAUSTED: INTERNAL_ERROR,
  NO_ASSOCIATED_HARDWARE: INTERNAL_ERROR,
};

export const REPORT_SERVER_HW_INVENTORY_VIEW_MAP = {
  SERVER: 'Server',
  SERVER_GENERATION: 'Generation',
  SERVER_MODEL: 'Model',
  SERVER_PROCESSOR: 'Processor',
  SERVER_MEMORY: 'Memory',
  SERVER_MANAGEMENT: 'Management',
  SERVER_DRIVE: 'Drives',
  SERVER_DEVICE_CONTROLLER: 'Device controllers',
};
export const REPORT_SERVER_HW_INVENTORY_PROPERTY_MAP = {
  SERVER: 'model',
  SERVER_PROCESSOR_VENDOR: 'processorVendor',
  SERVER_MODEL: 'model',
  SERVER_GENERATION: 'serverGeneration',
  SERVER_PROCESSOR: 'processor',
  SERVER_MEMORY: 'memory',
  SERVER_MANAGEMENT: 'management',
  SERVER_FORM_FACTOR: 'formFactor',
  SERVER_DRIVE: 'drives',
  SERVER_DEVICE_CONTROLLER: 'deviceControllers',
};

export const REPORT_SERVER_COMPONENTS_INVENTORY_VIEW_MAP = {
  SERVER_DEVICE_CONTROLLER: 'Device controllers',
  SERVER_DRIVE: 'Drive models',
  SERVER_DRIVE_LIFE_REMAINING: 'Drive life remaining',
};

export const REPORTS_VIEW_MAP = {
  SERVER: 'Server',
  UNKNOWN: 'Incomplete data',
  SERVER_FIRMWARE_COMPLIANCE: {
    SERVER: 'Server',
    GROUP: 'Group',
  },
};

export const REPORTS_SERIES_TYPE = {
  COMPLIANCE_DETAILS: 'COMPLIANCE_DETAILS',
};

export const REPORT_GENERIC_REASON_MAP = {
  FIRMWARE_REPORT: {
    SUBSCRIPTION_REQ: 'Subscription required',
    SUBSCRIPTION_EXPIRED: 'Subscription expired',
    SUBSCRIPTION_TIER: 'Enhanced subscription required',
    ILO_COMM_ERROR: 'Communication error with the server',
    INTERNAL_ERROR: 'Internal error occurred',
    NOT_CONNECTED: 'Server not connected',
    COMPLETE: 'Compliance calculated',
    INVENTORY_COLLECTED: 'Compliance calculated',
    FIRMWARE_BASLINE_NOT_SET: 'Firmware baseline not set on the group',
    ILO_SETTINGS_NOT_SET: 'Ilo setting not set on the group',
    UNKNOWN: 'Incomplete data',
    NOT_IN_GROUP: 'Server is not added to a group',
  },
};

export const SERIES_TYPE_CO2_EMISSIONS = 'CO2_EMISSIONS';
export const SERIES_TYPE_ENERGY_CONSUMPTION = 'ENERGY_CONSUMPTION';
export const SERIES_TYPE_ENERGY_COST = 'ENERGY_COST';
export const SPP_MIN_VERSION_LENGTH = 3;

export const SUBJECT_TYPE_SERVER_MODEL = 'SERVER_MODEL';
export const SUBJECT_TYPE_SERVER_GENERATION = 'SERVER_GENERATION';
export const SUBJECT_TYPE_SERVER_PROCESSOR_VENDOR = 'SERVER_PROCESSOR_VENDOR';
export const SUBJECT_TYPE_SERVER_LOCATION = 'SERVER_LOCATION';

export const DEFAULT_WIZARD_STEP_BUTTON_LABEL = 'Next';

export const USER_DEFINED_SETTING = 'User defined';
export const HPE_PREDEFINED_SETTING = 'HPE pre-defined';
export const API_DEFINED_SETTING = 'API defined';

export const BASELINE_UPDATE_TYPE = {
  GROUP: 'group',
  SERVER: 'server',
  LATEST_BASELINE: 'latestBaseline',
  NO_BASELINES: 'noBaselines',
};

export const APPLIANCE_UPDATE_TYPE = {
  GROUP: 'group',
  APPLIANCE: 'appliance',
};

export const SUPPORTED_LOCALES = [
  { name: 'Chinese', locale: 'zh-CN', code: 'cn' },
  { name: 'English', locale: 'en-US', code: 'en' },
  { name: 'French', locale: 'fr-FR', code: 'fr' },
  { name: 'German', locale: 'de-DE', code: 'de' },
  { name: 'Japanese', locale: 'ja-JP', code: 'ja' },
  { name: 'Korean', locale: 'ko-KR', code: 'ko' },
  { name: 'Portuguese', locale: 'pt-BR', code: 'br' },
  { name: 'Russian', locale: 'ru-RU', code: 'ru' },
  { name: 'Spanish', locale: 'es-ES', code: 'es' },
  { name: 'Italian', locale: 'it-IT', code: 'it' },
];
// OV page constant state filter
export const CONNECTED_STATE = 'Connected';
export const NOT_CONNECTED_STATE = 'Not connected';
export const NOT_ACTIVATED_STATE = 'Not activated';

// OV page constants (used as filter keys and to check values in DTOs)
export const CONNECTED = 'connected';
export const DISCONNECTED = 'disconnected';
export const NOT_ACTIVATED = 'not activated';
export const COMPLIANT = 'Compliant';
export const NOT_COMPLIANT = 'Not compliant';
export const OK = 'ok';
export const WARNING = 'warning';
export const CRITICAL = 'critical';
export const UNKNOWN = 'Unknown';
export const SERVERS_CATEGORY = 'server-hardware';
export const SERVERS_CATEGORY_FILTER = `eq(category,'${SERVERS_CATEGORY}')`;
export const ABSENT = 'Absent';
export const APPLIANCE_UPDATE = 'Appliance update';
export const APPLY_APPLIANCE_SETTINGS = 'Apply oneview settings';

// server hardware states in OV
export const SERVER_HW_STATES_IN_OV = {
  UNMANAGED: 'Unmanaged',
  PROFILE_APPLIED: 'ProfileApplied',
  NO_PROFILE_APPLIED: 'NoProfileApplied',
};

// Initial values for OV appliance cards
export const INITIAL_APPLIANCE_STATE = {
  total: 0,
  connected: 0,
  disconnected: 0,
  not_activated: 0,
};

// Initial values for OV appliance cards
export const INITIAL_APPLIANCE_STATE_SUMMARY = {
  total: 0,
  connected: 0,
  disconnected: 0,
  not_activated: 0,
  compliant: 0,
  not_compliant: 0,
  unknown: 0,
};

export const INITIAL_COMPLIANCE_COUNTS = {
  total: 0,
  compliant: 0,
  not_compliant: 0,
  unknown: 0,
};

export const JOB_STATE = {
  COMPLETE: 'Complete',
  RUNNING: 'Running',
  ERROR: 'Error',
  PENDING: 'Pending',
  STALLED: 'Stalled',
};

export const DRIVE_CONFIGURATION = {
  CONFIGURED: 'Configured',
  UNCONFIGURED: 'Unconfigured',
};

export const MAX_APPLIANCE_INELIGIBLE = 100;

export const MAX_ITEMS = 10000;

export const GROUP_ADD_REMOVE_MAX_DEVICES = 100;

export const HUDDLE_CHUNK_SIZE = 1024;

export const MIN_SNOW_REFRESH_TOKEN_EXPIRY_TO_NOTIFY = 15;

export const REPORT_TYPE = {
  SERVER_HARDWARE_INVENTORY: 'SERVER_HARDWARE_INVENTORY',
  CARBON_FOOTPRINT: 'CARBON_FOOTPRINT',
  SERVER_COMPONENTS_INVENTORY: 'SERVER_COMPONENTS_INVENTORY',
  SERVER_FIRMWARE_COMPLIANCE: 'SERVER_FIRMWARE_COMPLIANCE',
};

export const ALL_SERVERS_FILTER =
  '/compute-ops/v1beta1/filters/e7cef8e6-a3f6-4490-b669-136e3ca40617';

export const ALL_SERVERS_FILTER_ID = 'e7cef8e6-a3f6-4490-b669-136e3ca40617';

export const ALL_SERVERS_FILTER_NAME = 'All servers';

export const REPORT_FILE_NAME = {
  CARBON_FOOTPRINT: 'Carbon Footprint Report',
  SERVER_HARDWARE_INVENTORY: 'Server Hardware Inventory Report',
  SERVER_FIRMWARE_COMPLIANCE: 'Server Firmware Comliance Report',
  SERVER_COMPONENTS_INVENTORY: 'Server Components Inventory Report',
};

export const REPORT_NAME = {
  CARBON_FOOTPRINT: 'Carbon Footprint',
  SUSTAINABILITY: 'Sustainability',
};

// Browser local storage key for server table column customizations
export const SERVER_TABLE_COLUMNS_STORAGE_KEY =
  'COM_SERVER_TABLE_COLUMNS_SETTINGS';

// Default server table columns selected by property attribute
export const DEFAULT_SERVER_TABLE_COLUMNS_VIEW = {
  columns: [
    'selected',
    'hardware/health/summary',
    'host/hostname',
    'hardware/serialNumber',
    'iloSecurityStatus_',
    'state',
    'firmwareDisplayName',
    'group/name',
    'hardware/powerState',
    'tagsCount',
    'hardware/model',
  ],
  properties: {},
};

// this need to discuss/check.
export const FILTER_BY = [
  'All servers',
  'Manage filters',
  'My advanced filter',
];

export const SP_SERIAL_NUMBER_TYPE = {
  PHYSICAL: 'Physical',
  VIRTUAL: 'Virtual',
  USER_DEFINED: 'UserDefined',
};

export const SERVER_HARDWARE_CHASSIS_TYPE_BLADE = 'Blade';
export const OV_SERVER_HARDWARE_PLATFORM_TYPES = {
  COMPUTE_MODULE: 'ComputeModule',
  SUPERDOME_FLEX: 'Superdome Flex',
  RACK_MOUNT: 'RackMount',
};

export const SERVER_INVENTORY_TEMPLATE_YAML = `
name:
serverGeneration:
chassis:
    data :
        - ChassisType:
processor:
    data :
        - Status:
        - MaxSpeedMHz:
        - Oem:
          - Hpe:
            - RatedSpeedMHz:
        - TotalCores:
        - TotalThreads:
device:
    data:
        - Status:
        - Location:
        - Name:
        - ProductVersion:
        - FirmwareVersion:
        - SerialNumber:
        - PartNumber:
memory:
    data:
        - Status:
        - DeviceLocator:
        - MemoryDeviceType:
        - CapacityMiB:
        - OperatingSpeedMhz:
        - BaseModuleType:
        - SerialNumber:
        - PartNumber:
networkAdapter:
    data:
        - Controllers:
            - Location:
        - Model:
        - Ports:
        - PortId:
        - Ethernet:
            - AssociatedMACAddresses:
smartStorage:
    data:
        - Location:
        - Model:
        - PhysicalDrives:
            - Status:
            - Location:
            - MediaType:
            - Protocol:
            - CapacityMiB:
            - DiskDriveUse:
            - FirmwareVersion:
            - Model:
            - SerialNumber:
storage:
    data:
        - Controllers:
            - Model:
            - Location:
        - Drives:
            - Status:
            - PhysicalLocation:
            - MediaType:
            - Protocol:
            - CapacityBytes:
            - Links:
                - Volumes@odata.count:
        - SerialNumber:
        - Model:
        - PredictedMediaLifeLeftPercent:
        - Revision:
thermal:
    data:
        - Status:
        - Oem:
            - Hpe:
                - Location:
        - Name:
powerSupply:
    data:
        - Status:
        - Oem:
            - Hpe:
                - BayNumber:
        - Model:
        - PowerCapacityWatts:
        - FirmwareVersion:
        - SerialNumber:
`;

export const SERVER_INVENTORY_TEMPLATE_JSON = {
  lastFullInventoryCollectionPowerState: null,
  last_collected_time: null,
  chassis: {
    data: [
      {
        ChassisType: null,
        Oem: {
          Hpe: {
            FormFactor: null,
          },
        },
      },
    ],
  },
  processor: {
    data: [
      {
        Name: null,
        Model: null,
        Status: null,
        MaxSpeedMHz: null,
        Oem: {
          Hpe: {
            RatedSpeedMHz: null,
          },
        },
        TotalCores: null,
        TotalThreads: null,
      },
    ],
  },
  device: {
    data: [
      {
        Status: null,
        Location: null,
        Name: null,
        ProductVersion: null,
        FirmwareVersion: {
          Current: {
            VersionString: null,
          },
        },
        SerialNumber: null,
        PartNumber: null,
      },
    ],
  },
  memory: {
    data: [
      {
        Status: null,
        DeviceLocator: null,
        MemoryDeviceType: null,
        CapacityMiB: null,
        OperatingSpeedMhz: null,
        BaseModuleType: null,
        SerialNumber: null,
        PartNumber: null,
      },
    ],
  },
  networkAdapter: {
    data: [
      {
        Status: null,
        Controllers: [
          {
            Location: null,
          },
        ],
        Model: null,
        Ports: [
          {
            Ethernet: null,
            Id: null,
            PortId: null,
            '@odata.id': null,
          },
        ],
        NetworkDeviceFunctions: [
          {
            FibreChannel: {
              WWNN: null,
              WWPN: null,
            },
            Links: null,
          },
        ],
      },
    ],
  },
  storage: {
    data: [
      {
        Status: null,
        Controllers: [
          {
            SerialNumber: null,
            Location: null,
            Model: null,
          },
        ],
        Drives: [
          {
            SerialNumber: null,
            Status: null,
            PhysicalLocation: null,
            Location: null,
            LocationFormat: null,
            MediaType: null,
            Protocol: null,
            CapacityBytes: null,
            Links: null,
            Revision: null,
            PredictedMediaLifeLeftPercent: null,
            Model: null,
          },
        ],
      },
    ],
  },
  smartStorage: {
    data: [
      {
        SerialNumber: null,
        Location: null,
        Model: null,
        PhysicalDrives: [
          {
            Status: null,
            PhysicalLocation: null,
            Location: null,
            LocationFormat: null,
            MediaType: null,
            Protocol: null,
            CapacityMiB: null,
            DiskDriveUse: null,
            FirmwareVersion: {
              Current: null,
            },
            PredictedMediaLifeLeftPercent: null,
            Model: null,
            SerialNumber: null,
          },
        ],
      },
    ],
  },
  powerSupply: {
    data: [
      {
        Status: null,
        Oem: {
          Hpe: {
            BayNumber: null,
          },
        },
        PowerCapacityWatts: null,
        Model: null,
        FirmwareVersion: null,
        SerialNumber: null,
      },
    ],
  },
  thermal: {
    data: [
      {
        Status: null,
        Oem: {
          Hpe: {
            Location: null,
          },
        },
        Name: null,
      },
    ],
  },
  lastFullInventoryCollectionAt: null,
};

// default expiration time in hours for token based onnboarding using COM
export const DEFAULT_EXPIRATION_TIME_IN_HOURS = 12;

// default expiration time in days for PIN based onnboarding using COM
export const DEFAULT_3_DAYS_EXPIRATION_TIME_IN_HOURS = 72;

export const TARGET_DEVICE_SECURE_GATEWAY = 'SECURE_GATEWAY';
export const TARGET_DEVICE_ILO = 'ILO';

// maximum number of tags for adding direct connected servers
export const TAG_COUNT_LIMIT = 25;

// Timeout duration in minutes for os install completion
export const OS_INSTALL_TIMEOUT_VALUE = {
  '1 hour': 60,
  '1 hour 30 minutes': 90,
  '2 hours': 120,
  '3 hours': 180,
  '4 hours': 240,
  '6 hours': 360,
  '8 hours': 480,
  '12 hours': 720,
};
export const OS_INSTALL_DEFAULT_TIMEOUT = '4 hours';
export const OS_INSTALL_HOURS = 'hours';
export const OS_INSTALL_MINS = 'minutes';
export const DIRECT_CONNECTED_SERVERS = 'Direct';
export const OV_MANAGED_SERVERS = 'OneView managed';

export const STD_BUTTON_HEIGHT = '36px';

export const VRAN_PROFILE_SETTING_ID = '00000000-0000-0000-0000-800000000014';
export const PROLIANT_DL110_SERVER = 'DL110';

// permission slug to check in AuthContext's userPermissions
export const APPLIANCE_EDIT_SLUG = 'compute.oneview-appliance.edit';
export const APPLIANCE_CREATE_SLUG = 'compute.oneview-appliance.create';
export const SERVER_EDIT_SLUG = 'compute.server.edit';

export const OVERVIEW_PAGE_VIEWS = {
  TABLE: 'table',
  MAP: 'map',
};
export const AHS_LOG_PARSING_STATE = {
  ANALYSIS_IN_PROGRESS: 'ANALYSIS_IN_PROGRESS',
  ANALYSIS_SUCCESS: 'ANALYSIS_SUCCESS',
  ANALYSIS_FAILED: 'ANALYSIS_FAILED',
};
export const AHS_MAX_FILE_SIZE = 250 * 1024 * 1024; // 250 MB
export const AHS_MAX_FILE_SIZE_NON_GE = 100 * 1024 * 1024; // 100 MB
export const AHS_FILE_TYPE = 'ahs';
export const AHS_GEN_ERROR_CODE = 'AHSE-104';
export const APPLIANCE_SETTING_STATUS = {
  SUCCESS: 'SUCCESS',
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
};

export const SERVER_NOT_CONNECTED_STATE = 'Not connected';
export const MAP_CLUSTER_THRESHOLD = 1000;
export const MAP_TILE_LAYER_URL =
  'https://d2ezht69fz5a7f.cloudfront.net/grommet-leaflet/data/planet/{z}/{x}/{y}.mvt';
export const DEVICE_SUBSCRIPTION_ID = 'S0P96AAE';

export const OPERATIONAL_MODE = {
  MSP_OWNED_INVENTORY: 'MSP_OWNED_INVENTORY',
};

export const ADD_APPLIANCE_WIZARD_WIDTH = '576px';
export const SECURE_GATEWAY_ACTIVATION_DEFAULT_EXPIRY = '3 days';
export const CUSTOM_OS = 'CUSTOM';

export const MAX_DEVICE_ACTIVATION_KEY = 10;
export const MAX_SUB_KEY_COUNT = 10;
export const PROTOCOL = {
  HTTP: 'HTTP',
  HTTPS: 'HTTPS',
};

export const SERVER_COUNT_ZERO = 0;
export const ENHANCED_SECURITY_SETTINGS = {
  DIRECTORIES: 'directories',
  TWO_FACTOR_AUTH: 'twoFactorAuthenticationEnabled',
  LOCAL_LOGIN: 'allowLocalLogin',
};

// Default bounds for the map [[North-West], [South-East]]
export const DEFAULT_MAP_BOUNDS = [
  [-90, -180],
  [90, 180],
];

export const PROP_TYPES = {
  FUNC: PropTypes.func,
  OBJECT: PropTypes.object,
  BOOL: PropTypes.bool,
  FUNC_REQUIRED: PropTypes.func.isRequired,
  STRING_REQUIRED: PropTypes.string.isRequired,
  OBJECT_REQUIRED: PropTypes.object.isRequired,
  ARRAY_REQUIRED: PropTypes.array.isRequired,
  BOOL_REQUIRED: PropTypes.bool.isRequired,
};

export const ILO_SSO_TYPE = {
  REMOTE_CONSOLE: 'remote console',
  WEB_UI: 'web UI',
};

export const SUSTAINABILITY_ANALYSIS_GRAPH_TYPES = {
  LINE: 'line',
  BAR: 'bar',
};

export const DEFAULT_PROJECTION_DAYS = 30;
